import { FC, memo } from 'react';

import Allocation from 'components/Allocation';
import Amount from 'components/Amount';
import BaseListButton from 'components/BaseListButton';
import { OrderOrientation } from 'utils/sort/types';

import {
  Col,
  Content,
  FirstCol,
  Header,
  HeaderCell,
  HeaderContainer,
  HeaderTitle,
  Row,
  SortMobileButton,
  Table,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const PrivateInvestmentsAssetList: FC<Props> = ({
  className,
  data,
  isMobile,
  textButton,
  openModal,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const handlerClick = (id: string) => {
    sessionStorage.setItem('itemPosition', id);
  };

  return (
    <Table className={className}>
      <HeaderContainer>
        <Header>
          {headerColumns.map((col) => (
            <HeaderCell key={col.titleColumn}>
              <HeaderTitle
                onClick={() =>
                  handleSortByColumn ? handleSortByColumn(col) : undefined
                }
              >
                {col.titleColumn}
                {!isMobile &&
                  sortApplied?.titleColumn === col.titleColumn &&
                  (sortApplied?.orderOrientation ===
                  OrderOrientation.DESCENDENT ? (
                    <VectorDownIcon />
                  ) : (
                    <VectorUpIcon />
                  ))}
              </HeaderTitle>
            </HeaderCell>
          ))}
        </Header>
        {isMobile && (
          <SortMobileButton color="secondary" size="small" onClick={openModal}>
            {textButton || 'Sort'}
          </SortMobileButton>
        )}
      </HeaderContainer>
      <Content>
        {data.map((row) => (
          <BaseListButton
            key={`${row.id}-${row.value}`}
            pathname={row.pathname}
            onClick={() => handlerClick(row.id)}
          >
            <Row id={row.id}>
              <FirstCol>{row.name} </FirstCol>
              <Col>
                <Amount value={row.value} />
              </Col>
              <Col>
                <Allocation value={row.allocation} />
              </Col>
            </Row>
          </BaseListButton>
        ))}
      </Content>
    </Table>
  );
};

export default memo(PrivateInvestmentsAssetList);
