import { FC, memo, useEffect, useState } from 'react';

import Pagination from 'components/Pagination';
import type { ClientTransactionDatum } from 'model/ClientTransaction';
import { formatAmountWithDecimals, formatNumberDecimals } from 'utils/amounts';
import { formatDate } from 'utils/dates';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';

import {
  ColLeft,
  ColMoreInfo,
  ColouredAmount,
  Header,
  HeaderCell,
  HeaderTitle,
  LoaderContainer,
  MinusIcon,
  MoreInfo,
  MoreInfoRow,
  MoreInfoTitle,
  MoreInfoValue,
  PlusIcon,
  Row,
  Spinner,
  Table,
  TableContainer,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const NUM_TRANSACTIONS_PER_PAGE = 25;

const TransactionsTable: FC<Props> = ({
  data,
  loading,
  headerColumns,
  currentSorting,
  onChangeSorting,
}) => {
  const [sortColumn, setSortColumn] = useState<string>('Date');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [transactionsShown, setTransactionsShown] = useState<
    ClientTransactionDatum[]
  >([]);

  useEffect(() => {
    if (headerColumns) {
      const currentSortingAux = currentSorting ?? 'default';

      const headerColumn = headerColumns.find(
        (col) =>
          col.keyOrderAsc === currentSortingAux ||
          col.keyOrderDesc === currentSortingAux,
      );

      if (headerColumn) {
        setSortColumn(headerColumn.titleColumn);
        setSortOrientation(
          headerColumn.keyOrderAsc === currentSortingAux
            ? OrderOrientation.ASCENDENT
            : OrderOrientation.DESCENDENT,
        );
      }
    }
  }, [headerColumns, currentSorting]);

  const handlePageActive = (page: number) => {
    setTransactionsShown(
      data.slice(
        page * NUM_TRANSACTIONS_PER_PAGE,
        (page + 1) * NUM_TRANSACTIONS_PER_PAGE,
      ),
    );
  };

  useEffect(() => {
    setTransactionsShown(data.slice(0, NUM_TRANSACTIONS_PER_PAGE));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (onChangeSorting) {
      if (sortByColumnSelected.titleColumn === sortColumn) {
        onChangeSorting(
          sortOrientation === OrderOrientation.DESCENDENT
            ? sortByColumnSelected.keyOrderAsc
            : sortByColumnSelected.keyOrderDesc,
        );
      } else if (sortByColumnSelected.titleColumn !== sortColumn) {
        onChangeSorting(sortByColumnSelected.keyOrderDesc);
      }
    }
  };

  return (
    <TableContainer>
      <Table>
        <Header>
          {headerColumns.map((col) => (
            <HeaderCell key={col.titleColumn}>
              <HeaderTitle
                onClick={() =>
                  handleSortByColumn ? handleSortByColumn(col) : undefined
                }
              >
                <Title>{col.titleColumn}</Title>
                {sortColumn === col.titleColumn &&
                  (sortOrientation === OrderOrientation.DESCENDENT ? (
                    <VectorDownIcon />
                  ) : (
                    <VectorUpIcon />
                  ))}
              </HeaderTitle>
            </HeaderCell>
          ))}
        </Header>
        {!loading &&
          transactionsShown.map((row) => (
            <div key={row.id}>
              <Row key={row.id}>
                <ColLeft>{formatDate(new Date(row.date ?? 0))}</ColLeft>
                <ColLeft>{row.asset}</ColLeft>
                <ColLeft>{row.entity}</ColLeft>
                <ColLeft>{row.account}</ColLeft>
                <ColLeft>{row.description}</ColLeft>
                <ColouredAmount value={row.amount} withDecimals />
                <ColMoreInfo
                  onClick={() =>
                    setSelectedRow(selectedRow !== row.id ? row.id : undefined)
                  }
                >
                  {selectedRow === row.id ? <MinusIcon /> : <PlusIcon />}
                </ColMoreInfo>
              </Row>
              {selectedRow === row.id && (
                <MoreInfo>
                  <MoreInfoRow>
                    <MoreInfoTitle>Ticker Symbol/ISIN: </MoreInfoTitle>
                    <MoreInfoValue>
                      {row.ticker ? row.ticker : 'N/A'}
                    </MoreInfoValue>
                  </MoreInfoRow>
                  <MoreInfoRow>
                    <MoreInfoTitle>Transaction Type: </MoreInfoTitle>
                    <MoreInfoValue>{row.type}</MoreInfoValue>
                  </MoreInfoRow>
                  <MoreInfoRow>
                    <MoreInfoTitle>Category: </MoreInfoTitle>
                    <MoreInfoValue>{row.category}</MoreInfoValue>
                  </MoreInfoRow>
                  <MoreInfoRow>
                    <MoreInfoTitle>Asset Class: </MoreInfoTitle>
                    <MoreInfoValue>{row.assetClass}</MoreInfoValue>
                  </MoreInfoRow>
                  <MoreInfoRow>
                    <MoreInfoTitle>Number of Shares: </MoreInfoTitle>
                    <MoreInfoValue>
                      {formatNumberDecimals(row.shares)}
                    </MoreInfoValue>
                  </MoreInfoRow>
                  <MoreInfoRow>
                    <MoreInfoTitle>Price: </MoreInfoTitle>
                    <MoreInfoValue>
                      {formatAmountWithDecimals(row.price)}
                    </MoreInfoValue>
                  </MoreInfoRow>
                </MoreInfo>
              )}
            </div>
          ))}
        {loading && (
          <LoaderContainer>
            <Spinner />
          </LoaderContainer>
        )}
      </Table>
      {!loading && data.length > NUM_TRANSACTIONS_PER_PAGE && (
        <Pagination
          numRowsPerPage={NUM_TRANSACTIONS_PER_PAGE}
          dataSet={data as unknown[]}
          handlePageActive={handlePageActive}
        />
      )}
    </TableContainer>
  );
};

export default memo(TransactionsTable);
