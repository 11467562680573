import { parseISO } from 'date-fns';
import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';
import type { PickByValue } from 'utility-types';

import type { AssetAllocation } from 'model/AssetAllocation';
import type { AssetGrouping } from 'model/AssetGrouping';
import kebabCaseCustom from 'utils/kebabCaseCustom';
import { weightedAvg } from 'utils/math';

import { getKeyByGrouping } from './useAllocationGroups';
import useAllocations from './useAllocations';

function reduceAssets(
  assets: readonly [AssetAllocation, ...AssetAllocation[]],
) {
  function sum(key: keyof PickByValue<AssetAllocation, number | null>) {
    return assets.reduce(
      (total, asset) => (asset[key] ? Number(asset[key]) + total : total),
      0,
    );
  }

  function avg(key: keyof PickByValue<AssetAllocation, number>) {
    return weightedAvg<AssetAllocation>(
      (asset) => asset[key],
      (asset) => asset.value,
    )(assets);
  }

  function getIsinTicker() {
    return (
      assets.filter((asset) => asset.isinTicker !== '')[0]?.isinTicker ?? ''
    );
  }

  const first = assets[0];

  return {
    accruedInterest: sum('accruedInterest'),
    allocation: sum('allocation'),
    annualIncome: sum('annualIncome'),
    annualIncomeAfterTax: sum('annualIncomeAfterTax'),
    capitalCalled: sum('capitalCalled'),
    capitalCommitment: sum('capitalCommitment'),
    coupon: first.coupon,
    distributions: sum('distributions'),
    dividendsReceived: sum('dividendsReceived'),
    couponsReceived: sum('couponsReceived'),
    pnLExclCpn: sum('pnLExclCpn'),
    aIatPurchase: sum('aIatPurchase'),
    duration: avg('duration'),
    fullName: first.fullName,
    id: first.id,
    marketPrice: avg('marketPrice'),
    marketValue: sum('marketValue'),
    maturity: first.maturity,
    maturityDate:
      typeof first?.maturityDate === 'string'
        ? parseISO(first.maturityDate).getTime()
        : 0,
    moi: first.value === 0 ? first.moi : avg('moi'),
    name: first.name,
    pemv: sum('pemv'),
    rating: first.rating,
    rtgMOODY: first.rtgMOODY,
    rtgSP: first.rtgSP,
    rtgFITCH: first.rtgFITCH,
    shares: sum('shares'),
    slug: first.slug,
    subtype: first.subtype,
    sponsor: first.sponsor,
    toBeCalled: sum('toBeCalled'),
    totalPortfolioPercent: sum('totalPortfolioPercent'),
    totalProfitAndLoss: sum('totalProfitAndLoss'),
    ttc: avg('ttc'),
    value: sum('value'),
    ytw: avg('ytw'),
    tey: avg('tey'),
    contDollars: sum('contDollars'),
    cost: sum('cost') > 0 ? sum('cost') : 0,
    totalPortfolioAtCostPercent: sum('totalPortfolioAtCostPercent'),
    valueDate:
      typeof first?.valueDate === 'string'
        ? parseISO(first.valueDate).getTime()
        : 0,
    closingDate:
      typeof first?.closingDate === 'string'
        ? parseISO(first.closingDate).getTime()
        : 0,
    endOfInvPeriodDate:
      typeof first?.endOfInvPeriodDate === 'string'
        ? parseISO(first.endOfInvPeriodDate).getTime()
        : 0,
    maximumLiquidityDate:
      typeof first?.maximumLiquidityDate === 'string'
        ? parseISO(first.maximumLiquidityDate).getTime()
        : 0,
    closedPosition: first.closedPosition,
    isinTicker: getIsinTicker(),
    priceAsOfDate:
      typeof first?.priceAsOfDate === 'string'
        ? parseISO(first.priceAsOfDate).getTime()
        : 0,
    fiMultiplier: first.fiMultiplier,
    // netIRR: first.calculatedXIRR !== null ? sum('calculatedXIRR') : null,
    positionByAccountAssets: assets,
  } as const;
}

export default function useAllocationAssets({
  categorySlug,
  grouping,
  portfolio,
}: {
  categorySlug: string;
  grouping: { groupBy: AssetGrouping; subselection: string } | undefined;
  portfolio: string | undefined;
}) {
  const { data } = useAllocations(portfolio);

  const filtered = useMemo(() => {
    const filteredByCategory =
      data?.filter(
        (asset) => kebabCaseCustom(asset.instrumentType) === categorySlug,
      ) ?? [];

    if (grouping) {
      const selectingKey = getKeyByGrouping(grouping.groupBy);
      return filteredByCategory.filter(
        (asset) =>
          kebabCaseCustom(asset[selectingKey]) === grouping.subselection,
      );
    }

    return filteredByCategory;
  }, [data, categorySlug, grouping]);

  // The same asset might appear multiple times because they come from
  // different custodians or entities therefore the back treats it as different
  // assets. But to the user we show it only once.
  const reduced = useMemo(
    () =>
      Object.values(groupBy(filtered, (asset) => asset.fullName)).flatMap(
        (assets) => {
          const [first, ...rest] = assets;

          if (!first) {
            return [];
          }

          return [reduceAssets([first, ...rest])];
        },
      ),
    [filtered],
  );

  return {
    data: reduced,
  };
}

export type Asset = ReturnType<typeof reduceAssets>;
