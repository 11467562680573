import { FC, memo, useEffect, useState } from 'react';

import { formatAmount, formatNumberDecimals } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';
import { KeySortOfColumn, OrderOrientation } from 'utils/sort/types';

import {
  getColumnKeySelected,
  getHeaderColumns,
  getSortingData,
} from './logic';
import {
  ColCenter,
  ColLeft,
  ColouredAmount,
  Header,
  HeaderCell,
  HeaderTitle,
  Row,
  Table,
  TableContainer,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { PositionByAccountAsset, Props } from './types';

const PositionByAccountAssetTable: FC<Props> = ({
  data,
  categorySlug,
  nameExtraData,
}) => {
  const [sortColumn, setSortColumn] = useState<string>('Market Value');
  const [sortOrientation, setSortOrientation] = useState<string>(
    OrderOrientation.DESCENDENT,
  );
  const [dataSorted, setDataSorted] =
    useState<readonly PositionByAccountAsset[]>(data);

  const headerColumns = getHeaderColumns(nameExtraData);

  useEffect(() => {
    const key =
      getColumnKeySelected(sortColumn, sortOrientation, nameExtraData) || '';
    const sortedData = getSortingData(key, data);
    setDataSorted([...sortedData]);
  }, [categorySlug, data, sortColumn, sortOrientation, nameExtraData]);

  const handleSortByColumn = (sortByColumnSelected: KeySortOfColumn) => {
    if (sortColumn === sortByColumnSelected.titleColumn) {
      setSortOrientation(
        sortOrientation === OrderOrientation.ASCENDENT
          ? OrderOrientation.DESCENDENT
          : OrderOrientation.ASCENDENT,
      );
    } else {
      setSortColumn(sortByColumnSelected.titleColumn);
      setSortOrientation(OrderOrientation.DESCENDENT);
    }
  };

  return (
    <TableContainer>
      <Table>
        <Header $hasExtraData={categorySlug !== 'private-investments'}>
          {headerColumns.map(
            (col) =>
              col.titleColumn !== 'Net IRR' && (
                <HeaderCell key={col.titleColumn}>
                  <HeaderTitle
                    onClick={() =>
                      handleSortByColumn ? handleSortByColumn(col) : undefined
                    }
                  >
                    <Title>{col.titleColumn}</Title>
                    {sortColumn === col.titleColumn &&
                      (sortOrientation === OrderOrientation.DESCENDENT ? (
                        <VectorDownIcon />
                      ) : (
                        <VectorUpIcon />
                      ))}
                  </HeaderTitle>
                </HeaderCell>
              ),
          )}
        </Header>
        {dataSorted.map((row) => (
          <div key={`${row.custodian}-${row.account}`}>
            <Row
              key={`${row.custodian}-${row.account}`}
              $hasExtraData={categorySlug !== 'private-investments'}
            >
              <ColLeft>{row.custodian}</ColLeft>
              <ColLeft>{row.entity}</ColLeft>
              <ColLeft>{row.account}</ColLeft>
              <ColCenter>{formatNumberDecimals(row.shares)}</ColCenter>
              <ColCenter>{formatPercentage(row.percTotal)}</ColCenter>
              <ColCenter>{formatAmount(row.marketValue)}</ColCenter>
              <ColCenter>{formatAmount(row.cost)}</ColCenter>
              {categorySlug !== 'private-investments' && (
                <ColCenter>{formatAmount(row.extraData ?? 0)}</ColCenter>
              )}
              <ColouredAmount value={row.totalProfitAndLoss} />
            </Row>
          </div>
        ))}
      </Table>
    </TableContainer>
  );
};

export default memo(PositionByAccountAssetTable);
