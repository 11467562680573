import { FC, memo } from 'react';

import { formatAmount, formatNumberDecimals } from 'utils/amounts';
import { formatDate } from 'utils/dates';
import { formatPercentage } from 'utils/percentages';
import { OrderOrientation } from 'utils/sort/types';

import {
  Col,
  ColouredAmount,
  FirstCol,
  Header,
  HeaderCell,
  HeaderTitle,
  Row,
  Table,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const PrivateInvestmentsTable: FC<Props> = ({
  data,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => (
  <Table>
    <Header>
      {headerColumns.map((col) => (
        <HeaderCell key={col.titleColumn}>
          <HeaderTitle
            onClick={() =>
              handleSortByColumn ? handleSortByColumn(col) : undefined
            }
          >
            <Title>{col.titleColumn}</Title>
            {sortApplied?.titleColumn === col.titleColumn &&
              (sortApplied?.orderOrientation === OrderOrientation.DESCENDENT ? (
                <VectorDownIcon />
              ) : (
                <VectorUpIcon />
              ))}
          </HeaderTitle>
        </HeaderCell>
      ))}
    </Header>
    {data.map((row) => (
      <Row key={row.id}>
        <FirstCol>
          {row.name}
          <span>
            {`Valuation Date: ${
              row.valueDate > 0 ? formatDate(row.valueDate) : 'N/A'
            }`}
          </span>
        </FirstCol>
        <Col>
          {formatAmount(row.value)}
          <span>{formatPercentage(row.allocation)}</span>
        </Col>
        <Col>{formatAmount(row.capitalCommitment)}</Col>
        <Col>{formatAmount(row.capitalCalled)}</Col>
        <Col>{formatAmount(row.toBeCalled)}</Col>
        <Col>{formatAmount(row.distributions)}</Col>
        <ColouredAmount value={row.contDollars} />
        <Col>{formatNumberDecimals(row.moi)}</Col>
        {/* <Col>
          {row.netIRR !== null ? formatPercentage(row.netIRR ?? 0) : 'N/A'}
        </Col> */}
      </Row>
    ))}
  </Table>
);
export default memo(PrivateInvestmentsTable);
