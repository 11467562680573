import { useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import useAllocationAsset from 'api/hooks/allocations/useAllocationAsset';
import useAllocationGroups from 'api/hooks/allocations/useAllocationGroups';
import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';

import {
  useCurrentGrouping,
  useNavigationLinks,
} from '../CategoryDetail/logic';

export default function useConnect() {
  const {
    params: { assetSlug },
    url,
  } = useRouteMatch<{ assetSlug: string }>();
  const categorySlug = 'private-investments';
  const { search } = useLocation();
  const parentPath = useMemo(() => up(url, 2), [url]);
  const { selectedPortfolio } = useSelectedClient();

  const grouping = useCurrentGrouping({ search });

  const { getNameBySlug } = useAllocationGroups({
    groupBy: 'asset-breakdown',
    subselection: undefined,
    portfolio: selectedPortfolio?.id,
  });

  const { backUrl } = useNavigationLinks({
    categorySlug,
    getNameBySlug,
    grouping,
    search,
    url,
  });

  const asset = useAllocationAsset({
    assetSlug,
    categorySlug: 'private-investments',
    grouping,
    portfolio: selectedPortfolio?.id,
  });

  return {
    asset,
    backUrl,
    capitalCalled: asset?.capitalCalled ?? 0,
    commitment: asset?.capitalCommitment ?? 0,
    distributions: asset?.distributions ?? 0,
    marketValue: asset?.marketValue ?? 0,
    moi: asset?.moi ?? 0,
    // netIRR: asset?.netIRR ?? null,
    nominalAmount: asset?.shares ?? 0,
    parentPath,
    title: asset?.fullName ?? '',
    toBeCalled: asset?.toBeCalled ?? 0,
    totalPortfolioPercent: asset?.totalPortfolioPercent ?? 0,
    totalPortfolioAtCostPercent: asset?.totalPortfolioAtCostPercent ?? 0,
    contDollars: asset?.contDollars ?? 0,
    valueDate: asset?.valueDate ?? 0,
    closingDate: asset?.closingDate ?? 0,
    endOfInvPeriodDate: asset?.endOfInvPeriodDate ?? 0,
    maximumLiquidityDate: asset?.maximumLiquidityDate ?? 0,
    url,
    categorySlug,
  };
}
